import { render, staticRenderFns } from "./CareBlog.vue?vue&type=template&id=d4d104ca"
import script from "./CareBlog.vue?vue&type=script&lang=js"
export * from "./CareBlog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports